import api from '../../boot/api.js';
import { showToast } from '../../utilities/utilities.js';
const url = 'vendor';

const state = {
  vendorList: [],
  vendorSummary: []
}
const getters = {
  getVendors(state) {
    return state.vendorList
  },
  getVendorSummary(state) {
    return state.vendorSummary
  }
}
const actions = {
  getVendors(context, query) {
    api()
      .get(url, { params: query })
      .then((response) => {
        context.commit('getVendors', {
          data: response.data.results,
          totalRows: response.data.total
        })
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  createVendor(context, payload) {
    var bodyFormData = new FormData();
    payload.file && bodyFormData.append('vendor_logo', payload.file); // logo
    payload.file2 && bodyFormData.append('vendor_bg', payload.file2); // bg
    bodyFormData.append('first_name', payload.first_name);
    bodyFormData.append('last_name', payload.last_name);
    bodyFormData.append('email', payload.email);
    bodyFormData.append('name', payload.name);
    bodyFormData.append('phone_number', payload.phone_number);
    bodyFormData.append('address', payload.address);
    bodyFormData.append('level', payload.level);
    bodyFormData.append('bio', payload.bio);

    return api()
      .post(url, bodyFormData)
      .then(
        (response) => {
          showToast(context, 'Vendor Created Successfully.');
          return response;
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  updateVendor: function(context, { id, created_at, updated_at, ...payload }) {
    let bodyFormData = new FormData()

    bodyFormData.append('logo', payload.logo) // logo org
    bodyFormData.append('background', payload.background) // bg org
    payload.updated_logo && bodyFormData.append('updated_logo', payload.updated_logo)
    payload.updated_bg && bodyFormData.append('updated_bg', payload.updated_bg)
    bodyFormData.append('admin_id', payload.admin_id)
    bodyFormData.append('first_name', payload.first_name)
    bodyFormData.append('last_name', payload.last_name)
    bodyFormData.append('email', payload.email)
    bodyFormData.append('name', payload.name)
    bodyFormData.append('phone_number', payload.phone_number)
    bodyFormData.append('address', payload.address)
    bodyFormData.append('level', payload.level)
    bodyFormData.append('bio', payload.bio)

    return api()
      .put(url + `/${id}`, bodyFormData)
      .then(
        (response) => {
          showToast(context, 'Vendor Updated Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  deleteVendor(context, id) {
    return api()
      .delete(url + `/${id}`)
      .then(
        (response) => {
          showToast(context, 'Vendor Deleted Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  getVendorSummary(context, query) {
    const url = '/vendor/products'
    return api()
      .get(url, { params: query })
      .then(
        (response) => {
          context.commit('getVendorSummary', {
            data: response.data.results,
            totalRows: response.data.total
          })
        },
        (err) => {
          console.log('error', err)
        }
      )
  }
}
const mutations = {
  getVendors(state, payload) {
    state.vendorList = payload
  },
  getVendorSummary(state, payload) {
    state.vendorSummary = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
